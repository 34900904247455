.technology-rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    margin-bottom: 30px;
}

.icon i {
    font-size: 36px;
}

#last-row {
    margin-top: -15px;
    margin-bottom: -30px
}

.resume-absolute {
    position: relative
}

.resume-qr {
    width: 120px;
    position: relative;
    margin-left: 41px;
    margin-top: 25px;
}

// .hero-bg {
//     background-image: url("../public/images/portfolio_background1.JPG");
// }

@media (max-width: 991px) {
    .resume-qr {
        margin-left: 17px;
        margin-bottom: -30px;
    }
}

@media (max-width: 575px) {
    .resume-qr {
        margin-left: 7px;
        // margin-bottom: -40px;
    }
}

.heading1 {
    transform: translateX(-100px);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
}

.paragraph1 {
    transform: translateY(-100px);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
}

.visibleX {
    transform: translateX(0);
    opacity: 1;
}

.visibleY {
    transform: translateY(0);
    opacity: 1;
}

.stat {
    transform: scale(0.5);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
}

.visible-stat {
    transform: scale(1);
    opacity: 1;
}

#coding-languages {
    color: #20c997;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 400;

    &:hover {
        color: #1baa80;
    }
}

#building-click {
    color: #20c997;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 400;

    &:hover {
        color: #1baa80;
    }
}

.experience-bubble,
.education-bubble {
    transform: scale(1);
    // transition: transform ease-out 0.2s;
    transition: transform ease 0.3s;
}

.experience-bubble:hover,
.education-bubble:hover {
    transform: scale(1.1);
    // transition: transform ease 0.1s;
    transition: transform ease 0.1s;
}